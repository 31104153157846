<template>
  <div class="sprint-details">
    <p class="status" :class="status.class">{{ status.text }}</p>
    <template v-if="status.class !== 'future'">
      <span class="divider">•</span><p class="period">{{ period }}</p>
    </template>
    <template v-if="status.class !== 'history'">
      <span class="divider">•</span>
      <template v-if="status.class === 'future'">
        <p @click="$emit('new-sprint')" class="action">Start sprint</p>
      </template>

      <template v-else>
        <p @click="$emit('complete-sprint')" class="action">Complete sprint</p>
      </template>



    </template>
    <template v-if="status.class !== 'future'">
      <span class="divider">•</span>
      <div class="trash">
        <IconNew name="trash-blue-icon" class="icon"/>
        <p @click="$emit('open-trash')" class="action">Trash</p>
      </div>
    </template>
  </div>
</template>

<script>
import {ref, computed, watch, reactive} from "vue"
import moment from "moment"
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import IconNew from "@/components/UI-Kit/IconNew";
// status can de: history, active, future, overdue

const NOT_DATE = Date.parse("2000-09-10")

export default {
  name: "TheSprintsDetail",
  components: {IconNew, ButtonNew},
  props: {
    sprint: {
      type: Object,
      required: true
    }
  },
  setup (props, {emit}) {
    const status = computed(() => {
      if (!props.sprint.hasOwnProperty('startDate')) {
        return {
          class: 'future',
          text: 'Future'
        }
      } else if (props.sprint.hasOwnProperty('realEndDate') && Date.parse(props.sprint.realEndDate) !== NOT_DATE) {
        return {
          class: 'history',
          text: 'History'
        }
      } else if (props.sprint.hasOwnProperty('sync')){
        return {
          class: 'sync',
          text: 'Synchronization'
        }
      } else if (Date.parse(props.sprint.startDate) < Date.now() && Date.parse(props.sprint.endDate) > Date.now()) {
        return {
          class: 'active',
          text: 'Active'
        }
      } else {
        return {
          class: 'overdue',
          text: 'Overdue'
        }
      }
    })

    const period = computed(() =>
        `${moment(props.sprint.startDate).format('ddd, MMM D')} - ${moment(props.sprint.endDate).format('ddd, MMM D')}`)

    return {
      status,
      period
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.sprint-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.divider {
  margin: 0 5px;
  color: $pxp-gray-color-darken;
}

.trash {
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    margin-top: -2px;
  }
}

.action {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
  /* identical to box height, or 21px */


  color: $pxp-blue-added-color;

}

.status {
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  line-height: 150%;

  &.overdue {
    color: $pxp-red-added-color;
  }

  &.sync,
  &.history {
    color: $pxp-black-color;
  }

  &.active,
  &.future{
    color: $pxp-green-color;
  }

}

.period {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  color: $pxp-gray-color-darken;
}
</style>

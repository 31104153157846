import {ref} from "vue";

export const useBooleanController = function () {
    const controller = ref(false)
    const setTrue = function () {
        controller.value = true
    }
    const setFalse = function () {
        controller.value = false
    }

    return {
        controller,
        setTrue,
        setFalse
    }
}

export default useBooleanController

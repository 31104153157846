<template>
  <Modal id="done-rq-modal" header v-model:show="show" is-center padding-not-remove>
    <template v-slot:header>
      <h1><span class="rq-name">{{rq.name}} tasks </span> in <span class="done">done</span> 🎆</h1>
    </template>
    <div class="done-rq">
      <h3>What to do next with <span class="rq-name">{{rq.name}}</span>?</h3>
      <div class="actions">
        <div class="move-to-done action" @click="moveAction">
          <IconNew name="sprint-empty-done" class="icon"/>
          <p>Move to done</p>
        </div>
        <div class="continue-research action" @click="continueAction">
          <IconNew name="sprint-empty-rq" class="icon"/>
          <p>Continue research</p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/UI-Kit/Modal";
import {ref, watch, reactive} from "vue";
import IconNew from "@/components/UI-Kit/IconNew";
export default {
name: "TheSprintsResearchDoneModal",
  components: {IconNew, Modal},
  props: {
    show: Boolean,
    rq: {
      type: Object,
      required: true
    }
  },
  setup (props, {emit}) {

    watch(() => props.show, (newValue) => {
      emit("update:show", newValue)
    })

    const moveAction = function () {
      emit("move-to-done", props.rq)
      emit("update:show", false)
    }

    const continueAction = function () {
      // emit("continue-action")
      emit("update:show", false)
    }

    return {
      moveAction,
      continueAction
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.rq-name {

}
.done {
  color: $pxp-green-color
}

.done-rq {
  margin-top: 0;
  width: 100%;

  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    .action {
      margin: 20px;
      padding: 20px;
      width: 50%;
      border-radius: 8px;
      background-color: $pxp-white-color;
      box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover {
        box-shadow: 0 6px 16px -4px rgba(0, 0, 0, 0.1);
      }
      .icon {
        width: 64px;
        height: 64px;
        margin: 0 10px 5px 10px;
      }
      p {
        margin: 0 10px;
      }
    }
  }
}

.rq-name {
  font-style: italic;
}


</style>

<template>
  <div :class="['research-question-wrapper', item.sync? 'sync': '']">
    <div class="full-rq">
      <h3 @click="updateModalController.setTrue">
        {{item.name}}
      </h3>
      <!-- <Checker class="check" mode="rq" :id="'rq-full-' + researchQuestion.id" :check-list="checker"/> -->
    </div>
    <div class="tasks">
      <draggable
        :list="localTasks"
        :move="checkMove"
        group="tasks"
      >
        <template #header>

          <div class="rq-tasks">
            <h4>Tasks</h4>
            <div class="action">
              <template v-if="lastMove.column !== 'done' && lastMove.column !== 'deleted'">
                <PlusIcon @click="createNewTask"/>
              </template>
              <template v-else>
                <IconNew name="lock-icon"/>
              </template>
            </div>
          </div>

          <template v-if="localTasks.length === 0">
            <div class="empty-tasks">
              <p>You haven’t added any tasks yet.<br>
                What are you going to do to answer<br>
                this question?</p>
              <p>Click <PlusIcon @click="createNewTask"/></p>
            </div>

          </template>
        </template>
        <template #item="{element, index}">
          <SprintsTaskCard
            mode="rq"
            :item="element"
          />
        </template>
      </draggable>
    </div>


    <TheSprintsResearchUpdateModal
      :research-question="item"
      v-model:show="updateModalController.controller"
    />
  </div>
</template>

<script>
import {computed, reactive, inject} from "vue"
import draggable from "vuedraggable";
import {v1 as uuid} from "uuid";

import Checker from "@/components/UI-Kit/Checker";
import IconNew from "@/components/UI-Kit/IconNew";

import SprintsTaskCard from "@/components/SprintsTaskCard";
import TheSprintsResearchUpdateModal from "@/components/TheSprintsResearchUpdateModal";

import {createTask} from "@/api/sprint";

import {useMoveSetup} from "@/setups/moveSetup"
import useBooleanController from "@/setups/simple/useBooleanController";
import PlusIcon from "@/components/Icons/PlusIcon";

const setup = function (props, { emit }) {
  const tasks = inject('tasks')
  const sortedResearch = inject('sortedResearch')
  const getNeighborId = inject('getNeighborId')
  const date = inject('date')

  const { fixMove } = useMoveSetup(props)
  const updateModalController = reactive(useBooleanController())


  const lastMove = computed(() => props.item.move[props.item.move.length - 1])
  const localTasks = computed(() => sortedResearch.value[props.item.id] || [])

  const createNewTask = function () {
    const task = {
      id: uuid().split('-').join(''),
      type: 'tsk',
      name: '',
      sync: true,
      researchQuestion: props.item.id
    }

    tasks[task.id] = task

    fixMove(
        getNeighborId(task),
        '',
        '',
        localTasks.value.length !== 0? getNeighborId(localTasks.value[localTasks.value.length - 1]): 'rq-top',
        'rq-bottom',
        date.value(),
        props.item.move[props.item.move.length - 1].column
    )

    createTask(props.workspaceId, task)
        .then((data) => {
          delete tasks[syncId]
          data.type = 'tsk'
          data.sync = false
          tasks[data.id] = data
          fixMove('', '', data.move[0].neighborTop, data.move[0].neighborBottom, data.move[0].date, 'tsk-' + data.id)
        })

  }

  const checker = reactive([
    computed(() => props.item.name? props.item.name.length > 0: false),
    computed(() => props.item.details? props.item.details.length > 0: false),
    computed(() => props.item.learnings? props.item.learnings.length > 0: false),
    computed(() => props.item.plans? props.item.plans.length > 0: false)
  ])
  const checkMove = function () {
    if (props.item.move[props.item.move.length - 1].column === 'done' || props.item.move[props.item.move.length - 1].column === 'deleted')
      return false
  }

  return {
    createNewTask,
    checker,
    checkMove,
    localTasks,
    sortedResearch,
    updateModalController,
    lastMove
  }
}

export default {
  name: "SprintsResearchCard",
  components: {PlusIcon, TheSprintsResearchUpdateModal, SprintsTaskCard, Checker, IconNew, draggable},
  props: {
    item: {
      type: [Object, undefined],
      default: undefined
    },
    mode: {
      type: String,
      default: 'sprint',
      validate: (mode) => ['sprint', 'todo'].findIndex(item => item === mode) !== -1
    }
  },
  setup
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.research-question-wrapper {

  background: #FFFFFF;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;

  &.without-rq {
    margin-top: -20px;
  }

  margin-bottom: 8px;

  &.sync {
    border: 3px solid $pxp-white-accent-color-brighter;
  }

  h3 {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0 8px 8px 8px;
  }
}

.full-rq {
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
  }
  .check {
    height: 8px;
    margin:  0 8px;
  }
}

.rq-tasks {
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    text-align: left;
    font-size: 12px!important;
    line-height: 14px;
    margin: 16px 8px 8px 8px;
  }

  .action {
    margin: 16px 8px 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .icon {
      cursor: pointer;
      &:hover {

        .circle {
          fill: #0CAC60;
        }
        .plus {
          fill: #E3E5EC;
        }
      }
      .circle {
        fill: #E3E5EC;
      }
      .plus {
        fill: #13246E;
      }
    }

  }

}

.empty-tasks {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 24px 8px 24px 8px;
    /* or 21px */

    text-align: center;

    color: #80869D;
  }



}
</style>

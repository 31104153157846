<template>
  <Modal :id="'update-rq-' + researchQuestion.id + '-modal'" header footer v-model:show="show" is-center padding-not-remove>
    <template v-slot:header>
      <h1>Research Question</h1>
    </template>
    <div class="update-rq-form">
      <h2>What do you want to explore?</h2>

      <TextInputNew
          :id="'name-update-rq-' + researchQuestion.id"
          placeholder="Good research is driven by good questions"
          name="name-update-rq"
          v-model="researchQuestion.name"
          size="m"
      />

      <h2 class="details">Details / Methodological approach</h2>

      <TextArea mode="account"
                name="details-update-rq"
                placeholder="Optional"
                :id="'details-update-rq-' + researchQuestion.id"
                v-model="researchQuestion.details">

      </TextArea>

      <h2 class="details">Key learnings</h2>

      <TextArea mode="account"
                name="kl-update-rq"
                placeholder="Optional"
                :id="'kl-update-rq-' + researchQuestion.id"
                v-model="researchQuestion.learnings">

      </TextArea>

      <h2 class="details">Follow-on plans (if any)</h2>

      <TextArea mode="account"
                name="plans-update-rq"
                placeholder="Optional"
                :id="'plans-update-rq-' + researchQuestion.id"
                v-model="researchQuestion.plans">

      </TextArea>
    </div>

    <template v-slot:footer>
      <div class="action">
        <ButtonNew size="l" text="Done" mode="green" class="button" @click="updateResearchQuestionAction"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI-Kit/Modal";
import TextArea from "@/components/UI-Kit/TextArea";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import {ref, reactive, computed, watch, onMounted, nextTick, toRef, toRefs} from "vue"
import moment from "moment"
import {updateResearchQuestion} from "@/api/sprint";

export default {
  name: "TheSprintsResearchUpdateModal",
  components: {TextInputNew, TextArea, Modal, ButtonNew},
  props: {
    show: {
      type: Boolean
    },
    researchQuestion: {
      type: Object,
      required: true
    }
  },
  setup (props, {emit}) {
    const updateResearchQuestionAction = function () {
      props.researchQuestion.sync = true
      updateResearchQuestion(props.researchQuestion.id, props.researchQuestion.workspace, props.researchQuestion)
        .then((data) => {
          props.researchQuestion.sync = false
          emit('update:show', false)
        })

    }

    watch(() => props.show, (newValue) => {
      emit("update:show", newValue)
    })

    return {
      updateResearchQuestionAction
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/color-scheme.scss';
@import '~@/assets/styles/utility.scss';

.action {
  .button {
    width: 140px;
  }
}



.update-rq-form {/*
  @include suppress-scroll;
  max-height: 350px;
  overflow-y: scroll;*/

  .required {
    color: $pxp-red-color;
  }

  h2 {
    margin-bottom: 16px;
  }

  .details {
    margin-top: 32px;
  }
}

h1 {
  margin-top: 12px;
}


</style>

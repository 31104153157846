<template>
  <h1>Sprints</h1>
  <div class="empty-all">
    <IconNew
      name="sprint-first-icon"
      class="icon"
    />
    <h1 class="light">Let’s start your first sprint</h1>
    <ButtonNew
        mode="text"
        icon-name="video"
        text="How to use sprints in academic research?"
        size="s"
        icon-position="left"
        @click="showFirstVideo = true"
    />
    <p class="secondary-text">
      A Sprint is a short, time-boxed period when we try to complete a set amount of work.<br>
      Sprints help you break down big, complex tasks into bite-sized pieces and get things done easier.
    </p>
    <ButtonNew mode="green" text="Create Sprint" size="l" class="button" @click="$emit('new-sprint')"/>
  </div>

  <Modal
      id="goals-video-first-sprint"
      v-model:show="showFirstVideo"
      is-center
      class="video-modal"
  >
    <video
        src="https://res.cloudinary.com/dewnqhcdc/video/upload/v1607902031/video/Sprints_r00dl5.mp4"
        width="1920"
        height="1080"
        frameborder="0"
        uk-video="autoplay: inview"
        uk-responsive
    />
  </Modal>

</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import Modal from "@/components/UI-Kit/Modal";
import {ref} from "vue";

// TODO: remove old components and refactor new
export default {
  name: "TheSprintsExplanation",
  components: { IconNew, ButtonNew, Modal },
  setup () {
    const showFirstVideo = ref(false)

    return {
      showFirstVideo
    }
  }
}
</script>

<style scoped lang="scss">
.empty-all {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-items: center;
  align-self: center;
  width: calc(100vw - 100px);
  margin: auto;
  text-align: center;

  & .icon {
    width: 179px;
    height: 84px;
    margin: 0 auto;
  }

  & .button {
    width: 167px;
    margin: 0 auto;
  }
}
</style>

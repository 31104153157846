<template>
  <div class="section-empty">
    <IconNew
        :name="icon"
        class="icon"
    />
    <p class="secondary-text">
      <slot/>
    </p>
  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";

export default {
  name: "SprintsKanbanColumnEmpty",
  components: {IconNew},
  props: {
    icon: String
  }
}
</script>

<style scoped lang="scss">
.section-empty {
  border: 1px dashed #CCD0DB;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;


  .icon {
    width: 64px;
    height: 64px;

    margin: 72px auto 32px auto;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 72px;
  }
}
</style>

<template>
  <div class="sprints">

    <template v-if="statusLoadSprint === 'loading'">
      <h1>Sprints</h1>
      <CircleLoader :ratio="1.5" class="sprints-load"/>
    </template>

    <template v-else-if="statusLoadSprint === 'not found'">
      <TheSprintsExplanation @new-sprint="openModalCreate = true"/>
    </template>

    <template v-else-if="statusLoadSprint === 'loaded'">

      <TheSprintsChoice
        v-model:activeSprint="activeSprint"
        :sprints="sprints"
      />

      <TheSprintsDetail
        :sprint="sprints[activeSprint]"
        @complete-sprint="completeLastSprint"
        @new-sprint="openModalCreate = true"
        @open-trash="showTrash = !showTrash"
      />

      <TheSprintsKanban :date="date" :show-trash="showTrash" :workspace-id="workspaceId"/>

    </template>

    <TheSprintsNewModal v-model:show="openModalCreate" @start-sprint="createNewSprint"/>

  </div>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import {getSprint, createSprint, completeSprint} from "@/api/sprint";
import TheSprintsExplanation from "@/components/TheSprintsExplanation";
import TheSprintsKanban from "@/components/TheSprintsKanban";
import TheSprintsDetail from "@/components/TheSprintsDetail";
import TheSprintsNewModal from "@/components/TheSprintsNewModal";
import TheSprintsChoice from "@/components/TheSprintsChoice";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";


const sprintSetup = function (props) {
  const NOT_DATE = Date.parse("2000-09-10T00:00:00.000Z")

  const sprints = reactive([])
  const statusLoadSprint = ref('loading')
  const openModalCreate = ref(false)
  const showTrash = ref(false)
  const date = computed(() => {
    if (sprints[activeSprint.value].hasOwnProperty('realEndDate') && Date.parse(sprints[activeSprint.value].realEndDate) !== NOT_DATE) {
      return () => sprints[activeSprint.value].realEndDate
    } else {
      return () => (new Date()).toISOString()
    }
  })
  const activeSprint = ref(0)

  const createNewSprint = function (period) {

    if (sprints.length !== 0 && !sprints[sprints.length - 1].hasOwnProperty('startDate')) {
      sprints.splice(sprints.length - 1, 1)
    }

    const ind = sprints.push({
      sync: true,
      ...period
    })

    createSprint(props.workspaceId, period)
        .then((data) => {
          sprints[ind - 1] = data
        })

    activeSprint.value = sprints.length - 1

    if (statusLoadSprint.value === 'not found') {
      statusLoadSprint.value = 'loaded'
    }

    openModalCreate.value = false
  }
  const completeLastSprint = function () {
    const ind = sprints.length - 1

    completeSprint(props.workspaceId)
        .then((data) => sprints[ind] = data)

    sprints[ind].realEndDate = new Date().toISOString()
    sprints[ind].sync = true

    activeSprint.value = sprints.push({
      workspace: props.workspaceId
    }) - 1

  }

  const initSprint = function() {
    sprints.splice(0, sprints.length)
    statusLoadSprint.value = 'loading'
    activeSprint.value = 0

    getSprint(props.workspaceId).then(data => {
      if (data.length > 0) {
        // TODO: future if need
        data.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate))
        sprints.splice(0, 0, ...data)

        if (sprints[sprints.length - 1].hasOwnProperty('realEndDate') && Date.parse(sprints[sprints.length - 1].realEndDate) !== NOT_DATE) {
          sprints.splice(sprints.length, 0, {
            workspace: props.workspaceId
          })
        }

        activeSprint.value = sprints.length - 1
        statusLoadSprint.value = 'loaded'
      } else {
        statusLoadSprint.value = 'not found'
      }
    })
  }

  initSprint()

  watch(() => props.workspaceId, (value) => {
    initSprint()
  })

  return {
    NOT_DATE,
    sprints,
    statusLoadSprint,
    date,
    createNewSprint,
    activeSprint,
    openModalCreate,
    showTrash,
    completeLastSprint
  }
}

export default {
  name: "Sprints",
  components: {
    TheSprintsChoice,
    TheSprintsExplanation,
    TheSprintsDetail,
    TheSprintsKanban,
    TheSprintsNewModal,
    CircleLoader
  },
  props: {
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    return {
      ...sprintSetup(props)
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.sprints {
  background-color: #f4f5f8;
  width: 100vw;
  min-height: calc(100vh - 48px);
  padding: 50px;
  padding-bottom: 0;
  text-align: left;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.sprints-load {
  place-self: center center;
  margin: auto;
  height: 100%;
}



</style>

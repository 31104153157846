<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
       class="icon" >
    <circle cx="8" cy="8" r="7" class="circle"/>
    <path d="M9 4H7V7H4V9H7V12H9V9H12V7H9V4Z" class="plus"/>
  </svg>
</template>

<script>
export default {
  name: "PlusIcon"
}
</script>

<style scoped lang="scss">
.icon {
  margin-top: -4px;
  margin-left: 4px;
  cursor: pointer;
  &:hover {

    .circle {
      fill: #0CAC60;
    }
    .plus {
      fill: #E3E5EC;
    }
  }
  .circle {
    fill: #E3E5EC;
  }
  .plus {
    fill: #13246E;
  }
}
</style>

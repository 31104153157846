<template>
  <div class="column">
    <h2 class="black">{{ name }}</h2>
    <div class="active-section">
      <slot>

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SprintsKanbanColumnWrapper",
  props: {
    name: String
  }
}
</script>

<style scoped lang="scss">
.column {
  margin: 0 8px;
  box-sizing: border-box;
  height: 100%;
  align-self: start;

  h2 {
    font-weight: 400;
    margin-bottom: 16px;
  }

  .active-section {
    height: 100%;
    position: relative;
  }
}
</style>

<template>
  <div class="sprint-choice">
    <h1>Sprint {{ activeSprint + 1 }}</h1>
    <IconNew
      name="sprint-dropdown"
      class="dropdown-icon"
      :class="[dropdownOpen? 'open': '']"
      @click="dropdownOpen = !dropdownOpen"
    />
    <div
      class="sprint-choice-dropdown"
      uk-dropdown="boundary: .sprint-choice; boundary-align: true; offset: 5; animation: uk-animation-slide-top-small; duration: 100; mode:click; delay-hide: 0;"
    >
      <div class="sprint-choice-list">
        <template v-for="(sprint, index) in sprints" :key="'sprint-choice-item-' + index">
          <p class="sprint-choice-item"
             :class="[
               sprint.realEndDate && sprint.endDate &&
               Date.parse(sprint.realEndDate) !== Date.parse(NOT_DATE) &&
               Date.parse(sprint.realEndDate) <= Date.parse(sprint.endDate)? 'success': '',
               index === sprints.length - 1 && Date.parse(sprint.realEndDate) === Date.parse(NOT_DATE)? 'now': '',
               index === activeSprint? 'active': ''
             ]"
             @click="chooseSprint(index)"
          >Sprint {{index + 1}}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {onUpdated, ref} from "vue";
import UIkit from "uikit";
import IconNew from "@/components/UI-Kit/IconNew";

export default {
  name: "TheSprintsChoice",
  props: {
    activeSprint: Number,
    sprints: Array
  },
  components: {IconNew},
  setup (props, {emit}) {
    const dropdownOpen = ref(false)

    const chooseSprint = (index) => {
      UIkit.dropdown(document.querySelector('.sprint-choice-dropdown')).hide()
      emit('update:activeSprint', index)
    }

    const watchHide = function () {
      UIkit.util.on('.sprint-choice-dropdown', 'hide', function () {
        dropdownOpen.value = false
      });
    }

    onUpdated(() => {
      watchHide()
    })

    watchHide()

    return {
      dropdownOpen,
      chooseSprint
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.sprint-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  &.open {
    transform: rotate(180deg);
  }
}

.sprint-choice-dropdown {
  padding: 0;
}
.sprint-choice-list {
  padding: 0;
  .sprint-choice-item {
    cursor: pointer;
    background-color: $pxp-white-color;
    width: 100%;
    height: 40px;
    padding: 10px;

    &:hover {
      background-color: $pxp-white-color-darken;
    }

    &.now {

    }

    &.success {

    }

    &.active {
      background-color: $pxp-white-accent-color-brighter;
    }
  }
}

</style>

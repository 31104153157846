<template>
  <Modal id="start-sprint" header footer v-model:show="show" is-center padding-not-remove>
    <template v-slot:header>
      <h1>Start new sprint</h1>
    </template>
    <div class="cover">
      <IconNew name="sprint-first-icon" class="icon"/>
      <h1 class="light">Let’s start new sprint!</h1>
    </div>
    <div class="set-time">
      <div class="actions">
        <div class="two-week action" :class="[period === 14? 'active': '']" @click="setEnd(12096e5)">
          <!--<IconNew name="sprint-empty-done" class="icon"/>-->
          <p>For two weeks</p>
        </div>
        <div class="one-month action" :class="[period === 30? 'active': '']" @click="setEnd(2592000000)">
          <!--<IconNew name="sprint-empty-done" class="icon"/>-->
          <p>For a month</p>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <div class="action">
        <template v-if="period && period > 0">
          <p class="period">It's {{period}} days to do something great again</p>
        </template>
        <ButtonNew size="m" text="Start!" mode="green" class="button-start" @click="startSprint"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI-Kit/Modal";
import {ref, computed, watch, reactive} from "vue";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import IconNew from "@/components/UI-Kit/IconNew";
import moment from "moment"
import UIkit from "uikit"

const NOT_DATE = Date.parse("2000-09-10T00:00:00.000Z")

export default {
  name: "TheSprintsNewModal",
  components: {ButtonNew, TextInputNew, Modal, IconNew},
  props: {
    show: Boolean
  },
  setup (props, {emit}) {
    const startDate = ref(new Date().toISOString())
    const endDate = ref('')

    watch(() => props.show, (newValue) => {
      emit("update:show", newValue)
    })

    const period = computed(() => Math.floor(moment.duration(moment(endDate.value).diff(moment(startDate))).asDays()))

    const setEnd = function (time) {
      startDate.value = new Date().toISOString()
      endDate.value = (new Date(Date.now() + time)).toISOString()
    }

    const startSprint = function () {
      if (!endDate.value) {
        UIkit.modal.alert('Nothing selected!')
      } else {
        emit("start-sprint", {
          startDate: startDate.value,
          endDate: endDate.value
        })
        emit("update:show", false)
      }
    }



    return {
      startDate,
      endDate,
      startSprint,
      period,
      setEnd
    }
  }
}
</script>

<style scoped lang="scss">

@import "~@/assets/styles/color-scheme.scss";
.set-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  .input-date {
    margin: 10px;
  }
  .divider-time {
    font-size: 30px;
    margin: 5px;
  }
  margin-bottom: 5px;
}

.button-start {
  width: 140px;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
}

.cover {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 48px;
  & .icon {
    width: 179px;
    height: 84px;
    margin: 0 auto;
  }
  h1 {
    text-align: center;
    margin-top: 16px;
    color: $pxp-gray-color-darken;
  }
}

.period {
  color: $pxp-gray-color-darken;
  font-style: italic;
  margin: 0 14px;
  text-align: right;
}

</style>

<style lang="scss">
@import "~@/assets/styles/color-scheme.scss";
input#new-sprint-start-date{
  background-color: $pxp-gray-added-color!important;
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  .action {
    margin: 20px;
    padding: 20px;
    width: 50%;
    border-radius: 8px;
    background-color: $pxp-white-color;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover {
      box-shadow: 0 6px 16px -4px rgba(0, 0, 0, 0.1);
    }
    &.active {
      border: 1px solid $pxp-gray-color;
      box-shadow: 0 6px 16px -4px rgba(0, 0, 0, 0.1);
    }
    p {
      margin: 0 10px;
    }
  }
}
</style>

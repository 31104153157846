<template>
  <Modal
    id="create-rq-modal"
    header footer
    v-model:show="show"
    is-center
    padding-not-remove
  >
    <template v-slot:header>
      <h1>New Research Question</h1>
    </template>
    <div class="create-rq-form">
      <h2>What do you want to explore? <span class="required">*</span></h2>

      <TextInputNew
        id="name-new-rq"
        placeholder="Good research is driven by good questions"
        name="name-new-rq"
        size="m"
        v-model="researchName"
      />

      <h2 class="details">Details / Methodological approach</h2>

      <TextArea
        id="details-new-rq"
        mode="account"
        name="details-new-rq"
        placeholder="Optional"
        v-model="researchDetails"
      />
    </div>

    <template v-slot:footer>
      <div class="action">
        <ButtonNew
          size="l"
          text="Done"
          mode="green"
          class="button"
          @click="saveResearch"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI-Kit/Modal";
import TextArea from "@/components/UI-Kit/TextArea";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import {createResearchQuestion} from "@/api/sprint";
import {ref, reactive, computed, watch, onMounted, nextTick, toRef, toRefs} from "vue"
import moment from "moment"

const createResearchSetup = function(props, {emit}) {
  const researchName = ref('')
  const researchDetails = ref('')

  const reset = function () {
    researchName.value = ''
    researchDetails.value = ''
  }
  const saveResearch = function () {
    emit('new-rq-added', {
      name: researchName.value,
      details: researchDetails.value
    })
    emit('update:show', false)
    reset()
  }

  watch(() => props.show, (newValue) => {
    emit("update:show", newValue)
  })

  return {
    saveResearch,
    researchName, researchDetails
  }
}

export default {
  name: "TheSprintsResearchNewModal",
  components: {TextInputNew, TextArea, Modal, ButtonNew},
  props: {
    show: Boolean,
    neighborTop: {
      type: Object,
      required: true
    },
    neighborBottom: {
      type: Object,
      required: false,
      default: {
        type: 'column',
        id: 'bottom'
      }
    }
  },
  setup: createResearchSetup
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/color-scheme.scss';

.action {
  .button {
    width: 140px;
  }
}



.create-rq-form {
  .required {
    color: $pxp-red-color;
  }

  h2 {
    margin-bottom: 16px;
  }

  .details {
    margin-top: 32px;
  }
}

h1 {
  margin-top: 12px;
}

</style>

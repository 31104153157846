<template>
  <div class="kanban" v-if="sortedColumns">

    <!-- Trash

    <div class="trash" v-show="showTrash">
      <draggable
        :list="sortedData.deleted"
        group="tasks"
        class="dragzone"
        @change="fixChangeInDeleted"
      >
        <template #item="{element, index}">
          <template v-if="element.type === 'tsk'">
            <SprintsTaskCard
              class="task-card"
              mode="sprint"
              :task="element"
            />
          </template>
          <template v-else>
            <SprintsResearchCard
              class="rq-card"
              mode="sprint"
              :research-question="element"
              @new-task-added="addTaskToRqEnd"
            />
          </template>
        </template>
      </draggable>
    </div>
    -->

    <!-- Research question -->

    <SprintsKanbanColumnWrapper class="rq" name="Research question">
      <SprintsKanbanColumn
        :items="sortedColumns.rq"
      >
        <template #empty>
          <SprintsKanbanColumnEmpty icon="sprint-empty-rq">
            Good research is driven by<br>
            good questions. What do you want<br>
            to explore?
          </SprintsKanbanColumnEmpty>
        </template>
      </SprintsKanbanColumn>
    </SprintsKanbanColumnWrapper>

    <!-- To Do -->

    <SprintsKanbanColumnWrapper class="todo" name="To Do">
      <SprintsKanbanColumn
        :items="sortedColumns.todo"
      >
        <template #empty>
          <SprintsKanbanColumnEmpty icon="sprint-empty-todo">
            Drag here to start
          </SprintsKanbanColumnEmpty>
        </template>
      </SprintsKanbanColumn>
    </SprintsKanbanColumnWrapper>

    <!-- Doing -->

    <SprintsKanbanColumnWrapper class="doing" name="Doing">
      <SprintsKanbanColumn
          :items="sortedColumns.doing"
      >
        <template #empty>
          <SprintsKanbanColumnEmpty icon="sprint-empty-doing">
            Deep dive and investigate
          </SprintsKanbanColumnEmpty>
        </template>
      </SprintsKanbanColumn>
    </SprintsKanbanColumnWrapper>

    <!-- Done -->

    <SprintsKanbanColumnWrapper class="done" name="Done">
      <SprintsKanbanColumn
          :items="sortedColumns.done"
      >
        <template #empty>
          <SprintsKanbanColumnEmpty icon="sprint-empty-done">
            What have you learnt?
          </SprintsKanbanColumnEmpty>
        </template>
      </SprintsKanbanColumn>
    </SprintsKanbanColumnWrapper>

  </div>
  <TheSprintsResearchDoneModal
    v-if="sortedData"
    v-model:show="openDoneRqModal"
    :rq="doneRq"
    @move-to-done="moveRqToDone"
  />
  <TheSprintsResearchNewModal
    v-if="sortedData"
    v-model:show="openCreateRqModal"
    @new-rq-added="createResearch"
  />
</template>

<script>
import {inject, ref, watch} from "vue"

import draggable from "vuedraggable";
import {createResearchQuestion, createTask, moveResearchQuestion, moveTask} from "@/api/sprint";
import SprintsTaskCard from "@/components/SprintsTaskCard";
import IconNew from "@/components/UI-Kit/IconNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import TheSprintsResearchNewModal from "@/components/TheSprintsResearchNewModal";
import TheSprintsResearchDoneModal from "@/components/TheSprintsResearchDoneModal";
import SprintsResearchCard from "@/components/SprintsResearchCard";
import {v1 as uuid} from "uuid";
import SprintsKanbanColumnWrapper from "@/components/SprintsKanbanColumnWrapper";
import SprintsKanbanColumnEmpty from "@/components/SprintsKanbanColumnEmpty";
import SprintsKanbanColumn from "@/components/SprintsKanbanColumn";



const useMoveSetup = function (props) {
  const tasks = inject('tasks')
  const rqs = inject('rqs')

  const createNewBottomNeighborMove = function (move, bottom, date) {
    return  {
      date: date,
      neighborTop: move.neighborTop,
      neighborBottom: bottom,
      column: move.column
    }
  }
  const createNewTopNeighborMove = function (move, top, date) {
    return {
      date: date,
      neighborTop: top,
      neighborBottom: move.neighborBottom,
      column: move.column
    }
  }
  const createNewMove = function (top, bottom, column, date) {
    return {
      date: date,
      neighborTop: top,
      neighborBottom: bottom,
      column: column
    }
  }
  const getNeighborObject = function (neighborId) {
    const entity = neighborId.split('-')[0]
    const id = neighborId.split('-')[1]
    return entity === 'rq'? rqs[id] : tasks[id]
  }
  const getNeighborLastMove = function (neighborObject) {
    return neighborObject.move[neighborObject.move.length - 1]
  }
  const removeNeighborLastMove = function (neighborObject) {
    delete neighborObject.move[neighborObject.move.length - 1]
  }
  const addNewMove = function (obj, move) {
    if (!obj.hasOwnProperty('move'))
      obj.move = []

    obj.move.splice(obj.move.length, 0, move)
  }

  const sync = async function (obj, move) {
    obj.sync = true
    const method = obj.type === 'rq'? moveResearchQuestion : moveTask

    await method(obj.id, props.workspaceId, move)
    obj.sync = false
  }

  const removeSyncMove = function(top, bottom) {
    if (top !== 'rq-top' && top !== 'column-top' && top !== '') {
      const neighborObject = getNeighborObject(top)
      removeNeighborLastMove(neighborObject)
    }

    if (bottom !== 'rq-bottom' && bottom !== 'column-bottom' && bottom !== '') {
      const neighborObject = getNeighborObject(bottom)
      removeNeighborLastMove(neighborObject)
    }
  }

  const fixMove = function(lastTop, lastBottom, newTop, newBottom, date, self, column) {

    if (lastTop !== 'rq-top' && lastTop !== 'column-top' && lastTop !== '') {
      const neighborObject = getNeighborObject(lastTop)
      const neighborLastMove = getNeighborLastMove(neighborObject)
      const neighborNewMove = createNewBottomNeighborMove(neighborLastMove, lastBottom, date)

      addNewMove(neighborObject, neighborNewMove)
    }

    if (lastBottom !== 'rq-bottom' && lastBottom !== 'column-bottom' && lastBottom !== '') {
      const neighborObject = getNeighborObject(lastBottom)
      const neighborLastMove = getNeighborLastMove(neighborObject)
      const neighborNewMove = createNewTopNeighborMove(neighborLastMove, lastTop, date)

      addNewMove(neighborObject, neighborNewMove)
    }

    if (newTop !== 'rq-top' && newTop !== 'column-top' && newTop !== '') {
      const neighborObject = getNeighborObject(newTop)
      const neighborLastMove = getNeighborLastMove(neighborObject)
      const neighborNewMove = createNewBottomNeighborMove(neighborLastMove, self, date)

      addNewMove(neighborObject, neighborNewMove)
    }

    if (newBottom !== 'rq-bottom' && newBottom !== 'column-bottom' && newBottom !== '') {
      const neighborObject = getNeighborObject(lastBottom)
      const neighborLastMove = getNeighborLastMove(neighborObject)
      const neighborNewMove = createNewTopNeighborMove(neighborLastMove, self, date)

      addNewMove(neighborObject, neighborNewMove)
    }

    if (self) {
      const object = getNeighborObject(self)
      const newMove = createNewMove(newTop, newBottom, column, date)

      addNewMove(object, newMove)
    }
  }

  return {
    fixMove,
    removeSyncMove,
    sync
  }
}
const useResearchSetup = function (props) {
  const openCreateRqModal = ref(false)
  const openDoneRqModal = ref(false)
  const doneRq = ref({})

  const tasks = inject('tasks')
  const rqs = inject('rqs')
  const sortedData = inject('sortedData')
  const date = inject('date')

  const {fixMove, removeSyncMove} = useMoveSetup(props)

  const createResearch = function(research) {
    console.log(research)
    research.id = uuid().split('-').join('')
    research.sync = true
    research.type = 'rq'

    const neighborTop = sortedData['rq'].length === 0? 'column-top' : getNeighborId(sortedData['rq'][sortedData['rq'].length - 1])
    const neighborBottom = 'column-bottom'

    rqs[research.id] = research

    fixMove(
      '', '',
      neighborTop, neighborBottom,
      date.value(), getNeighborId(research), 'rq'
    )

    createResearchQuestion(props.workspaceId, research)
        .then((data) => {
          delete rqs[research.id]
          data.sync = false
          data.type = 'rq'
          rqs[data.id] = data
          removeSyncMove(neighborTop, neighborBottom)
          fixMove('', '',
              neighborTop, neighborBottom,
              data.move[0].date, getNeighborId(data), 'rq'
          )
        })
  }

  const moveTasksToRq = async function(rq, column) {
    const date = new Date().toISOString()

    for (let ind in rq.tasks) {
      const index = Number.parseInt(ind)
      const task = rq.tasks[index]

      const taskFull = tasks[task.id]
      const lastMove = taskFull.move[taskFull.move.length - 1]

      const newTop = index === 0? 'rq-top' : 'tsk-' + rq.tasks[index - 1].id
      const newBottom = 'rq-bottom'

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + taskFull.id)

      const selfTaskMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: column
      }

      tasks[task.id].move.push(selfTaskMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + taskFull.id)

      taskFull.sync = true

      await moveTask(taskFull.id, props.workspaceId, selfTaskMove)
          .then(() => {
            taskFull.sync = false
          })

    }
  }

  const moveRqToDone = async function(rq) {
    const date = new Date().toISOString()
    const lastMove = rq.move[rq.move.length - 1]
    const newTop = 'column-top'
    const newBottom = sortedData.value.done.length !== 0?
        sortedData.value.done[0].type + '-' + sortedData.value.done[0].id : 'column-bottom'

    fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'rq-' + rq.id)

    const selfMove = {
      date: date,
      neighborTop: newTop,
      neighborBottom: newBottom,
      column: 'done'
    }

    rq.move.push(selfMove)

    fixMove("", "", newTop, newBottom, date, 'rq-' + rq.id)

    rqs[rq.id].sync = true

    await moveResearchQuestion(rq.id, props.workspaceId, selfMove)

    await moveTasksToRq(rq, 'done')
    rqs[rq.id].sync = false
  }

  return {
    doneRq,
    moveRqToDone,
    openCreateRqModal,
    openDoneRqModal,
    createResearch,
    tasks,
    rqs
  }
}
const useTaskSetup = function (props) {

  const addTaskToRqEnd = function(task) {

  }

  return {
    addTaskToRqEnd
  }
}

const setup = function (props) {

  // main data
  const tasks = inject('tasks')
  const rqs = inject('rqs')

  // filter tasks
  const sortedColumns = inject('sortedColumns')
  const findPlaceByDate = inject('findPlaceByDate')
  const date = inject('date')

  // init
  const loadStatus = inject('loadRQStatus')

  date.value = props.date

  watch(() => props.date, (value) => {
    date.value = props.date
  })

  // Fix move
  const { fixMove, sync } = useMoveSetup(props)

  // Fix move in column

  const fixChangeInRq = function (changeInfo) {
   if (changeInfo.hasOwnProperty('added')) {
     console.log('added-rq', changeInfo)
   /*   // only tasks
      const { element, newIndex } = changeInfo.added

      if (element.type === 'rq') {
        const date = new Date().toISOString()
        const newNeighborTop = newIndex > 0? getNeighborId(sortedData.value.rq[newIndex - 1]) : 'column-top'
        const newNeighborBottom = newIndex < sortedData.value.rq.length? getNeighborId(sortedData.value.todo[newIndex]) : 'column-bottom'
        const { neighborTop, neighborBottom } = element.move[element.move.length - 1]

        fixMove(neighborTop, neighborBottom, newNeighborTop, newNeighborBottom, date, 'rq-' + element.id, 'rq')
      }
      else {
        let newTop, newBottom, rqId
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.leng    th - 1]

        if (typeof element.researchQuestion === 'object')
          rqId = element.researchQuestion.id
        else
          rqId = element.researchQuestion

        const newRqIndex = rqs[rqId].rqList.length

        if (newIndex > 0) {
          let elementTop = rqs[rqId].rqList[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'rq-top'
        }

        newBottom = 'rq-bottom'

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'rq'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      }*/
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      console.log('moved-rq', changeInfo)
      /*const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.rq[newIndex - 1]
      else
        newTop = sortedData.value.rq[newIndex]

      if (newIndex === sortedData.value.rq.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.rq[newIndex]
      else
        newBottom = sortedData.value.rq[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'rq-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'rq'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'rq-' + element.id)

      rqs[element.id].sync = true

      moveResearchQuestion(element.id, props.workspaceId, selfMove)
          .then(() => {
            rqs[element.id].sync = false
          })
    */
    }
  }
  const fixChangeInTodo = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      // only tasks
      console.log('added', changeInfo)
      /*const { element, newIndex } = changeInfo.added

        if (element.type === 'tsk') {
          let newTop, newBottom
          const date = new Date().toISOString()
          let lastMove = element.move[element.move.length - 1]

          if (newIndex > 0) {
            let elementTop = sortedData.value.todo[newIndex - 1]
            newTop = elementTop.type + '-' + elementTop.id
          } else {
            newTop = 'column-top'
          }

          if (newIndex < sortedData.value.todo.length) {
            let elementBottom = sortedData.value.todo[newIndex]
            newBottom = elementBottom.type + '-' + elementBottom.id
          } else {
            newBottom = 'column-bottom'
          }

          fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

          const selfMove = {
            date: date,
            neighborTop: newTop,
            neighborBottom: newBottom,
            column: 'todo'
          }

          element.move.push(selfMove)

          fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

          tasks[element.id].sync = true

          moveTask(element.id, props.workspaceId, selfMove)
              .then(() => {
                tasks[element.id].sync = false
              })
        } else {
          alert('Only task!')
        }
    */}
    else if (changeInfo.hasOwnProperty('moved')) {
      console.log('moved', changeInfo)

      /*const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.todo[newIndex - 1]
      else
        newTop = sortedData.value.todo[newIndex]

      if (newIndex === sortedData.value.todo.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.todo[newIndex]
      else
        newBottom = sortedData.value.todo[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'todo'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })*/
    }
  }
  const fixChangeInDoing = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      console.log('added', changeInfo)
      /*// only tasks
      const { element, newIndex } = changeInfo.added

      if (element.type === 'tsk') {
        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.doing[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.doing.length) {
          let elementBottom = sortedData.value.doing[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'doing'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      } else {
        alert('Only task!')
      }*/
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      console.log('moved', changeInfo)
      /*const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.doing[newIndex - 1]
      else
        newTop = sortedData.value.doing[newIndex]

      if (newIndex === sortedData.value.doing.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.doing[newIndex]
      else
        newBottom = sortedData.value.doing[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'doing'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })*/
    }
  }
  const fixChangeInDone = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      // only tasks
      const { element, newIndex } = changeInfo.added

      if (element.type === 'tsk') {

        // check all in done:

        let rqId, flag = true

        if (typeof element.researchQuestion === 'object')
          rqId = element.researchQuestion.id
        else
          rqId = element.researchQuestion

        rqs[rqId].tasks.forEach(item => {
          let task = tasks[item.id]
          let taskPlace = findPlaceByDate(task, props.date())
          if ((item.id !== element.id) && (!taskPlace || taskPlace.column !== 'done')) {
            flag = false
          }
        })

        if (flag) {
          doneRq.value = rqs[rqId]
          openDoneRqModal.value = true
        }

        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.done[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.done.length) {
          let elementBottom = sortedData.value.done[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'done'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      } else {
        alert('Only task!')
      }
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.done[newIndex - 1]
      else
        newTop = sortedData.value.done[newIndex]

      if (newIndex === sortedData.value.done.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.done[newIndex]
      else
        newBottom = sortedData.value.done[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'done'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })
    }
  }
  const fixChangeInDeleted = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      const { element, newIndex } = changeInfo.added

      if (element.type === 'tsk') {

        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.deleted[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.deleted.length) {
          let elementBottom = sortedData.value.deleted[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'deleted'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      } else {
        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.deleted[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.deleted.length) {
          let elementBottom = sortedData.value.deleted[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'rq-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'deleted'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'rq-' + element.id)

        rqs[element.id].sync = true

        moveResearchQuestion(element.id, props.workspaceId, selfMove)
            .then(() => {
              moveTasksToRq(rqs[element.id], 'deleted')
                .then(() => {
                  rqs[element.id].sync = false
                })
            })
      }
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.done[newIndex - 1]
      else
        newTop = sortedData.value.done[newIndex]

      if (newIndex === sortedData.value.done.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.done[newIndex]
      else
        newBottom = sortedData.value.done[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'done'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })
    }
  }

  return {
    sortedColumns,
    loadStatus,/*
    fixChangeInTodo,
    fixChangeInDoing,
    fixChangeInDone,
    fixChangeInRq,
    fixChangeInDeleted,
    ...useTaskSetup(props),
    ...useResearchSetup(props)*/
  }
}

export default {
  name: "TheSprintsKanban",
  components: {
    SprintsKanbanColumn,
    SprintsKanbanColumnEmpty,
    SprintsKanbanColumnWrapper,
    TheSprintsResearchNewModal,
    TheSprintsResearchDoneModal,
    SprintsResearchCard,
    SprintsTaskCard,
    ButtonNew,
    IconNew,
    draggable
  },
  props: {
    workspaceId: {
      type: String,
      required: true
    },
    date: {
      type: Function,
      required: true
    },
    showTrash: {
      type: Boolean,
      required: true
    }
  },
  setup
};
</script>

<style scoped lang="scss">
.kanban {
  margin-top: 16px;
  overflow-y: hidden;
  flex-grow: 1;
  overflow-x: hidden;
  display: grid;
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 161px);
  align-items: start;
  grid-template-areas:
      "trash trash trash trash"
      "rq todo doing done";

  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: max-content;


  .trash {
    position: relative;
    grid-area: trash;
    height: 250px;
    width: calc(100% - 16px);
    margin: 0 8px 24px 8px;
    border: 1px dashed #CCD0DB;
    align-self: start;
    border-radius: 8px;

    .dragzone {
      overflow-y: scroll;
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-start;
      .task-card, .rq-card {
        margin: 4px;
      }
    }
  }


  .rq {
    grid-area: rq;
    height: 100%;
  }
  .todo {
    grid-area: todo;
    height: 100%;
  }
  .doing {
    grid-area: doing;
    height: 100%;
  }
  .done {
    grid-area: done;
    height: 100%;
  }

}


.section-empty {
  border: 1px dashed #CCD0DB;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;


  .icon {
    width: 64px;
    height: 64px;

    margin: 72px auto 32px auto;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 72px;
  }
}

.rq-active {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;

  .separate {
    height: 225px;
  }

  .button {
    margin: 24px auto 124px auto;
    width: 173px;
  }
}



.dragzone {
  //border: 1px solid red;
}
</style>

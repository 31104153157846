<template>
  <slot v-if="items.length === 0" name="empty"/>
  <draggable
      :list="items"
      group="tasks"
      class="dragzone"
      @change="fixChange"
  >
    <template #item="{element, index}">
      <SprintsTaskCard
        v-if="element.type === 'tsk'"
        mode="sprint"
        :item="element"
      />
      <SprintsResearchCard
        v-else
        mode="sprint"
        :item="element"
      />
    </template>
    <template #footer>
      <slot name="footer"/>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import SprintsKanbanColumnEmpty from "@/components/SprintsKanbanColumnEmpty";
import SprintsTaskCard from "@/components/SprintsTaskCard";
import SprintsResearchCard from "@/components/SprintsResearchCard";
export default {
  name: "SprintsKanbanColumn",
  components: {
    SprintsResearchCard,
    SprintsTaskCard,
    SprintsKanbanColumnEmpty,
    draggable
  },
  props: {
    items: Array
  },
  setup () {
    const fixChange = function () {
      return 0
    }

    return {
      fixChange
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/utility.scss";

.dragzone {
  @include suppress-scroll;
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

</style>
